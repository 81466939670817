var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{staticClass:"course__item course__lesson",class:_vm.lesson.studied ? 'course__item--complete' : 'course__item--active',attrs:{"to":_vm.lesson.access || _vm.isEditorCourse ?
        {
            path: ("/courses/" + (_vm.$route.params.course_id) + "/lesson/" + (_vm.lesson.id)),
            query: {
                module: _vm.moduleId
            }
        } :
        '',"tag":"div"}},[(_vm.isEditorCourse && _vm.accessEdit)?_c('div',{staticClass:"course__lesson-dragicon"},[_c('i',[_c('icon',{attrs:{"height":"16","width":"11"}},[_c('circle',{attrs:{"cx":"2.375","cy":"2","r":"2"}}),_c('circle',{attrs:{"cx":"2.375","cy":"8","r":"2"}}),_c('circle',{attrs:{"cx":"2.375","cy":"14","r":"2"}}),_c('circle',{attrs:{"cx":"8.375","cy":"2","r":"2"}}),_c('circle',{attrs:{"cx":"8.375","cy":"8","r":"2"}}),_c('circle',{attrs:{"cx":"8.375","cy":"14","r":"2"}})])],1)]):_vm._e(),_c('div',{staticClass:"course__lesson-index"},[_vm._v(" "+_vm._s(_vm.moduleIndex + '.' + _vm.lessonIndex)+" ")]),_c('div',{staticClass:"course__lesson-title"},[_vm._v(_vm._s(_vm.lesson.name))]),(_vm.lesson.new_comment && _vm.isEditorCourse)?_c('i',{staticClass:"course__lesson-comments-icon"}):_vm._e(),(_vm.isEditorCourse && _vm.accessEdit)?_c('lesson-submenu',{staticClass:"course__lesson-action item-admin-action",attrs:{"lesson-id":_vm.lesson.id,"data":_vm.lesson,"module-id":_vm.moduleId}}):(_vm.isEditorCourse && !_vm.accessEdit)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({content: _vm.placeholders.COURSE_EDIT_FORBIDDEN, offset: 7}),expression:"{content: placeholders.COURSE_EDIT_FORBIDDEN, offset: 7}"}],staticClass:"submenu__icon"}):_vm._e(),(_vm.course.started && _vm.course.access && !_vm.lesson.studied && !_vm.isEditorCourse)?_c('router-link',{staticClass:"v-btn",attrs:{"to":_vm.lesson.access || _vm.isEditorCourse ? ("/courses/" + (_vm.$route.params.course_id) + "/lesson/" + (_vm.lesson.id)) : '',"tag":"button","disabled":!_vm.lesson.access}},[_vm._v("Изучить")]):(!_vm.isEditorCourse && _vm.lesson.studied)?_c('div',{staticClass:"course__lesson-action item-action"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }